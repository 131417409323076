export default [
  // Dashboard and Member
  {
    header: 'Dashboard and Member',
    resource: 'User',
  },
  {
    title: 'แดชบอร์ด',
    route: 'dashboard-partner',
    icon: 'icon fad fa-chart-line',
    resource: 'User',
  },
  {
    title: 'รายการฝากออโต้',
    route: 'deposit',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'User',
  },
  {
    title: 'รายการฝากโดยแอดมิน',
    route: 'deposit-fromadmin',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'Agadmin',
  },
  {
    title: 'รายการถอน',
    route: 'withdraw',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'User',
  },
  {
    title: 'โยกเงิน',
    route: 'movecredit',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'Agadmin',
  },
  {
    title: 'รายการลูกค้า',
    route: 'member',
    icon: 'icon fad fa-users-crown',
    resource: 'User',
  },
  {
    title: 'ประวัติแก้ไขข้อมูลลูกค้า',
    route: 'history-UserEditlog',
    icon: 'icon fad fa-file-alt',
    resource: 'Agadmin',
  },
  {
    header: 'Dashboard Detail',
    resource: 'Agadmin',
  },
  {
    title: 'ข้อมูลธุรกรรม',
    route: 'dashboard-detail',
    icon: 'icon fad fa-chart-line',
    resource: 'Agadmin',
  },
  // {
  //   title: 'ฝาก-ถอน',
  //   route: 'deposit-withdraw',
  //   icon: 'icon fad fa-money-check-edit-alt',
  //   resource: 'Agadmin',
  // },
  {
    header: 'Invite Friend',
    resource: 'Agadmin',
  },
  {
    title: 'แนะนำ (เซียน)',
    route: 'invite-friend',
    icon: 'icon fad fa-user-friends',
    resource: 'Agadmin',
  },
  {
    title: 'ส่งข้อความ SMS',
    route: 'send-sms',
    icon: 'icon fad fa-sms',
    resource: 'Agadmin',
  },
  {
    header: 'Ganeral Setting',
    resource: 'Agadmin',
  },
  {
    title: 'ตั้งค่าหน้าลูกค้า',
    route: 'customer-setting',
    icon: 'icon fad fad fa-desktop-alt',
    resource: 'Admin',
  },
  {
    title: 'ตั้งค่าค่ายเกมส์',
    route: 'games-setting',
    icon: 'icon fad fad fa-gamepad',
    resource: 'Agadmin',
  },
  {
    title: 'ตั้งค่าระบบ',
    route: 'system-setting',
    icon: 'icon fad fa-cogs',
    resource: 'Admin',
  },
  {
    header: 'Lucky wheel',
    resource: 'User',
  },
  {
    title: 'ตั้งค่ากงล้อเสี่ยงโชค',
    route: 'wheel-setting',
    icon: 'icon fad fad fa-dharmachakra',
    resource: 'User',
  },
  {
    title: 'รายการเล่นกงล้อเสี่ยงโชค',
    route: 'wheel-history',
    icon: 'icon fad fad fa-dharmachakra',
    resource: 'User',
  },
  {
    header: 'Affliate',
    resource: 'User',
  },
  {
    title: 'ตั้งค่า Affliate',
    route: 'affliate',
    icon: 'icon fad fa-star',
    resource: 'Agadmin',
  },
  {
    title: 'คอมมิชชั่นลูกค้า',
    route: 'affliate-history',
    icon: 'icon fad fas fa-pennant',
    resource: 'User',
  },
  {
    title: 'รอบปันผล Affliate',
    route: 'affliate-round',
    icon: 'icon fad fas fa-file-invoice-dollar',
    resource: 'User',
  },
  {
    header: 'Jackpot',
    resource: 'User',
  },
  {
    title: 'ตั้งค่า Jackpot',
    route: 'jackpot',
    icon: 'icon fad fas fa-sack-dollar',
    resource: 'Agadmin',
  },
  {
    title: 'รอบปันผล Jackpot',
    route: 'jackpot-history-round',
    icon: 'icon fad far fa-hands-usd',
    resource: 'User',
  },
  {
    title: 'ประวัติรายงาน Jackpot',
    route: 'jackpot-history',
    icon: 'icon fad fad fa-file-invoice-dollar',
    resource: 'User',
  },
  // AG Admin Management
  // {
  //   header: 'SBOBET Setting',
  //   resource: 'SBO',
  // },
  // {
  //   title: 'โต๊ะ SBOBET',
  //   route: 'sbobet-table',
  //   icon: 'icon fad fa-trophy-alt',
  //   resource: 'SBO',
  // },
  // {
  //   title: 'ตั้งค่า SBOBET',
  //   route: 'sbobet-setting',
  //   icon: 'icon fad fa-trophy-alt',
  //   resource: 'Agadmin',
  // },

  // {
  //   title: 'รายงาน Win/Lose',
  //   route: 'report',
  //   icon: 'icon fad fa-trophy-alt',
  //   resource: 'Agadmin',
  // },

  {
    header: 'AG Admin Management',
    resource: 'Agadmin',
  },
  {
    title: 'ธนาคาร',
    route: 'bank',
    icon: 'icon fad fa-analytics',
    resource: 'Agadmin',
  },
  {
    title: 'ช่องทางรู้จัก',
    route: 'channel',
    icon: 'icon fad fa-ad',
    resource: 'Agadmin',
  },
  {
    title: 'ยูสเซอร์แอดมิน',
    route: 'admin-management',
    icon: 'icon fad fa-address-card',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติการเข้าใช้งาน',
    route: 'log-admin',
    icon: 'icon fad fa-address-book',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติการอนุมัติรายการถอน',
    route: 'log-withdraw',
    icon: 'icon fad fa-address-book',
    resource: 'Agadmin',
  },
  // {
  //   title: 'จัดการบัญชีเอเย่นต์',
  //   route: 'admin-agent-management',
  //   icon: 'icon fad fa-user-secret',
  //   resource: 'Agadmin',
  // },
  {
    title: 'โปรโมชั่น',
    route: 'promotion',
    icon: 'icon fad fa-gift-card',
    resource: 'Agadmin',
  },
  {
    title: 'ตั้งค่าเว็บไซต์',
    route: 'setting',
    icon: 'icon fad fa-users-cog',
    resource: 'Agadmin',
  },
  {
    title: 'ประวัติหน้าตั้งค่าเว็บไซต์',
    route: 'history',
    icon: 'icon fad fa-file-alt',
    resource: 'Agadmin',
  },
  {
    title: 'ระบบถอนออโต้ SCB',
    route: 'auto-withdraw-scb',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'Agadmin',
  },
  // {
  //   title: 'บัญชีโยกเงิน',
  //   route: 'movebankcheck',
  //   icon: 'icon fad fa-hand-holding-usd',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'ระบบถอนออโต้ KBANK',
  //   route: 'auto-withdraw-kbank',
  //   icon: 'icon fad fa-hand-holding-usd',
  //   resource: 'Agadmin',
  // },
  // SMS Status
  {
    header: 'Error List',
    resource: 'User',
  },
  {
    title: 'รายการฝากไม่สำเร็จ',
    route: 'waiting-dep',
    icon: 'icon fad fa-exclamation',
    resource: 'User',
  },
  {
    title: 'รายการคืนเงิน',
    route: 'deposit-admin',
    icon: 'icon fad fa-comments-alt-dollar',
    resource: 'Agadmin',
  },
  {
    header: 'SMS Status',
    resource: 'User',
  },
  {
    title: 'SMS ฝาก',
    route: 'sms-deposit',
    icon: 'icon fad fa-comment-alt-dollar',
    resource: 'User',
  },
  {
    title: 'SMS ถอน',
    route: 'sms-withdraw',
    icon: 'icon fad fa-comment-alt-dollar',
    resource: 'User',
  },
  {
    title: 'SMS All',
    route: 'sms-all',
    icon: 'icon fad fa-comment-alt-dollar',
    resource: 'Agadmin',
  },
  {
    title: 'SMS OTP',
    route: 'sms-otp',
    icon: 'icon fad fa-comment-alt-exclamation',
    resource: 'User',
  },

  {
    title: 'True Money Wallet',
    route: 'true-money',
    icon: 'icon fad fa-comment-alt-lines',
    resource: 'User',
  },
  // {
  //   title: 'KPLUS GEN',
  //   route: 'kplus-gen',
  //   icon: 'icon fad fa-university',
  //   resource: 'Agadmin',
  // },
  {
    title: 'KBANK STATEMENT',
    route: 'kbank-statement',
    icon: 'icon fad fa-university',
    resource: 'User',
  },
  // {
  //   title: 'KBANK LIVE',
  //   route: 'kbank-live',
  //   icon: 'icon fad fa-university',
  //   resource: 'User',
  // },
  // {
  //   title: 'SCB Connect',
  //   route: 'scb-connect',
  //   icon: 'icon fad fa-university',
  //   resource: 'User',
  // },
  {
    title: 'SCB Statement',
    route: 'statement',
    icon: 'icon fad fa-money-check',
    resource: 'User',
  },
  {
    title: 'Crypto STATEMENT',
    route: 'crypto-statement',
    icon: 'icon fad fa-university',
    resource: 'User',
  },
  // Setting
  // {
  //   header: 'Admin Management',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'Agent List',
  //   route: 'agent-list',
  //   icon: 'SettingsIcon',
  //   resource: 'Agadmin',
  // },
]
